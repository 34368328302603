// snackbarManager.js
import { Snackbar, Alert } from '@mui/material';
import React, { useState } from 'react';

let showSnackbar = () => {}; // Function placeholder

export const SnackBarManager = {
  setShowSnackbar: (fn) => {
    showSnackbar = fn;
  },
  notify: (message, severity = 'info', variant="filled") => {
    showSnackbar(message, severity, variant);
  }
};

export const SnackBarContainer = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
    variant: 'filled'
  });

  SnackBarManager.setShowSnackbar((message, severity, variant) => {
    setSnackbar({
      open: true,
      message,
      severity,
      variant
    });
  });

  const handleClose = () => {
    setSnackbar(prev => ({
      ...prev,
      open: false
    }));
  };

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={snackbar.severity} variant={snackbar.variant}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};
