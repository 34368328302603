/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import Loading from "../components/Loading";

const baseRoute = '/dashboard/driver'
const routesConfig = [
  {
    exact: true,
    path: "/",
    component: lazy(() => import("./Driver/HomeView")),
  },
  {
    exact: true,
    path: "/payout/weekly",
    component: lazy(() => import("./Driver/PayoutWeeklyView")),
  },
  {
    exact: true,
    path: "/payout/weekly/detail",
    component: lazy(() => import("./Driver/PayoutWeeklyDetailView")),
  },
  {
    exact: true,
    path: "/payout/monthly",
    component: lazy(() => import("./Driver/PayoutMonthlyView")),
  },
  {
    exact: true,
    path: "/payout/monthly/detail",
    component: lazy(() => import("./Driver/PayoutMonthlyDetailView")),
  },
  {
    exact: true,
    path: "/payout/monthly",
    component: lazy(() => import("./Driver/PayoutMonthlyView")),
  },
  {
    exact: true,
    path: "/payout/daily",
    component: lazy(() => import("./Driver/PayoutDailyView")),
  },
  {
    exact: true,
    path: "/todays_earning",
    component: lazy(() => import("./Driver/TodaysEarningView")),
  },
  {
    exact: true,
    path: "/rate_cards",
    component: lazy(() => import("./Driver/RateCardsView")),
  },
  {
    exact: true,
    path: "/rate_cards/detail/base_rate",
    component: lazy(() => import("./Driver/RateCardBaseRateView")),
  },
  {
    exact: true,
    path: "/rate_cards/detail/incentives",
    component: lazy(() => import("./Driver/RateCardIncentivesView")),
  },
  {
    exact: true,
    path: "/rate_cards/detail/min_gtd",
    component: lazy(() => import("./Driver/RateCardMinGtdView")),
  },
  {
    exact: true,
    path: "/surges",
    component: lazy(() => import("./Driver/SurgeCardsView")),
  },
];

const renderRoutes = (routes) =>
  <Suspense fallback={
    <Box sx={{ width: '100vw', height: '100vh', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Loading />
    </Box>
  }>
    <Routes>
      {routes.map((route, i) => {
        const Component = route.component;

        return (
          <Route
            key={i}
            path={baseRoute + route.path}
            exact={route.exact}
            element={<Component />}
          />
        );
      })}
    </Routes>
  </Suspense>

function RoutesConfigured() {
  return renderRoutes(routesConfig);
}

export default RoutesConfigured;
