import React from "react";
import { CircularProgress, Grid } from '@mui/material';

const Loading = () => {
  return (
    <Grid container direction="column" justify="center" alignItems="center">

      <CircularProgress sx={{color: "#689F38"}} />

    </Grid>
  );
};

export default Loading;
