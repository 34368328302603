import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import RoutesConfigured from './views/routes';
import theme from './theme';
import { SnackBarContainer } from './utils/snackBarManager'; // Import and call renderSnackbar

function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <RoutesConfigured />
        <SnackBarContainer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
