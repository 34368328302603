import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#ECEFF1',
            contrastText: '#455A64'
        },
        secondary: {
            main: '#689F38',
            contrastText: '#FFFFFF'
        },
        text: {
            primary: '#545F71',
            secondary: '#ffffff',

        }
    },
    typography: {
        fontFamily: 'Proxima Nova, sans-serif',

        title1: {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '20px'
        },
        subtitle1: {
            fontWeight: '400',
            fontSize: '15px',
            lineHeight: '20px',
            letterSpacing: '0.1px'
        },
        heading1: {
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "18px",
            letterSpacing: "0em",
        },
        subHeading1: {
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '16px',
            letterSpacing: '0em',
        },
        subHeading2: {
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '14px',
        },
        body1: {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '16px'
        },
        body3: {
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '14px'
        },
        overline1: {
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '14px',
        },
        chipPaymentCard: {
            fontSize: '12px',
            lineHeight: '18px',
            letterSpacing: '0.16px'
        },

        m3LabelLarge: {
            fontFamily: 'Roboto',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.1px'
        },
        overline2: {
            fontWeight: '600',
            fontSize: '10px',
            lineHeight: '12px',
        },

        disclaimer: {
            fontWeight: '600',
            fontSize: '10px',
            lineHeight: '12px',
        },
        caption1: {
            fontWeight: '600',
            fontSize: '10px',
            lineHeight: '12px',

        },
        caption2: {
            fontWeight: '400',
            fontSize: '10px',
            lineHeight: '12px',
        }

    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                html: {
                    margin: 0,
                },
            },
        },
    },
});

export default theme;
